import React from "react"
import { Link } from "gatsby"

const CaseStudyPostCard = (props) => {
  const authorImage = props.content.authorImage ? props.content.authorImage : require("../assets/icons/author.svg")
  return (
      <Link to={`/case-studies/${props.content.slug}`} data-sal="slide-up" data-sal-easing="ease" data-sal-duration="600">
        <div className="blog-post-card">
          <img src={props.content.image} className="blog-post-card__img" alt="blogpost" />
          <div className="d-flex flex-column justify-content-between w-50">
            <p className="blog-post-card__title">{props.content.title}</p>
          </div>
        </div>
        </Link>
    )
}

export default CaseStudyPostCard
